<!-- Cta button -->

<template>
  <span class="hs-cta-wrapper" :id="`hs-cta-wrapper-${urlComponents[6]}`"
    ><span
      class="hs-cta-node"
      :class="`hs-cta-${urlComponents[6]}`"
      :id="`hs-cta-${urlComponents[6]}`"
      ><v-btn
        :class="buttonClass"
        :href="hubspotCtaHref"
        :target="target"
        :title="title"
        class="font-weight-bold"
        color="primary"
        tag="a"
        rel="noopener"
        x-large
      >
        <!--         
        <i class="el-icon m-r-10" v-if="icon" :class="`el-${icon}`"></i
        > -->

        <span v-if="!$slots.default" style="margin-left: 0">{{ text }}</span>
        <slot></slot> </v-btn></span
  ></span>
</template>

<script>
export default {
  name: "HubspotCtaButton",
  props: {
    text: {
      type: String,
      default: "Save",
    },
    buttonClass: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    target: {
      type: String,
      default: "_blank",
    },
    hubspotCtaHref: {
      type: String,
      required: true,
      default: "",
    },
    hubspotCtaOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      urlComponents: this.hubspotCtaHref.split("/"),
    };
  },
  mounted() {
    console.log("one");
    // We get the params from the link (so it's easier for the user)
    // The CTA link should look like:
    // https://cta-redirect.hubspot.com/cta/redirect/2853558/caccc178-4b51-40e6-9b18-6f1f53281a60

    // If CTA load code is already present we don't need to add the script again
    if (window.hbspt && window.hbspt.cta && window.hbspt.cta.load) {
      console.log("two");
      return;
    }

    this.getScript("https://js.hscta.net/cta/current.js").then(() => {
      console.log("HERE IN THEN PROMNISE");
      setTimeout(() => {
        this.loadCta();
      }, 500);
    });
  },
  methods: {
    getScript(src) {
      console.log("four");
      // if (document.querySelector("script[src='" + src + "']")) {
      //   return;
      // }

      return new Promise((resolve) => {
        let script = document.createElement("script");
        script.setAttribute("src", src);
        script.setAttribute("type", "text/javascript");
        document.head.appendChild(script);
        resolve();
      });
    },

    loadCta() {
      console.log("HERE LOAD CTA");
      // Add Hubspot special parameters (it will end up changing the URL);
      window.hbspt.cta.load(
        Number(this.urlComponents[5]),
        this.urlComponents[6],
        this.hubspotCtaOptions || {}
      );
    },
  },
};
</script>
